/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
aside.main-nav-layout {
  height: 100vh;
  background: -webkit-linear-gradient(#004cfe 0%, #0035b0 100%);
  background: linear-gradient(#004cfe 0%, #0035b0 100%);
  overflow: hidden;
  z-index: 999;
}
aside.main-nav-layout .main-nav-content {
  width: 100%;
  height: -webkit-calc(100% - 101px);
  height: calc(100% - 101px);
  overflow-x: hidden;
  overflow-y: auto;
  /* overwrite antd menu styles */
}
aside.main-nav-layout .main-nav-content .logo-btn {
  width: 60px;
  height: 60px;
  display: block;
  background-image: url("/mm-logo-mark.svg");
  background-size: 75%;
  background-position: center center;
  background-repeat: no-repeat;
}
aside.main-nav-layout .main-nav-content .logo-btn.devmode {
  background-color: darkred;
}
aside.main-nav-layout .main-nav-content .logo-btn.fullsize {
  width: 100%;
  height: 125px;
  background-image: url("/mm-logo-white.svg");
  background-size: 85%;
}
aside.main-nav-layout .main-nav-content .ant-menu {
  background-color: transparent;
}
aside.main-nav-layout .main-nav-content .ant-menu.ant-menu-inline-collapsed {
  width: 60px;
}
aside.main-nav-layout .main-nav-content .ant-menu.ant-menu-inline-collapsed .ant-menu-item {
  padding: 0 16px;
}
aside.main-nav-layout .main-nav-content .ant-menu.ant-menu-inline-collapsed .ant-menu-item a::before {
  width: 60px;
}
aside.main-nav-layout .main-nav-content .ant-menu.ant-menu-inline-collapsed .ant-menu-item a span:nth-child(2) {
  display: none;
}
aside.main-nav-layout .main-nav-content .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title {
  padding: 0 16px;
}
aside.main-nav-layout .main-nav-content .ant-menu.ant-menu-inline-collapsed .ant-menu-submenu .ant-menu-submenu-title .ant-menu-title-content span:nth-child(2) {
  display: none;
}
aside.main-nav-layout .main-nav-content .ant-menu .ant-menu-item {
  width: 100%;
  height: 60px;
  margin: 0;
  line-height: 60px;
}
aside.main-nav-layout .main-nav-content .ant-menu .ant-menu-submenu .ant-menu-submenu-title {
  width: 100%;
  height: 60px;
  margin: 0;
  line-height: 60px;
}
aside.main-nav-layout .main-nav-content .ant-menu .anticon {
  font-size: 24px;
  line-height: 24px;
  vertical-align: middle;
}
aside.main-nav-layout .main-nav-content .ant-menu .anticon svg {
  width: 24px;
  height: 24px;
}
aside.main-nav-layout .main-nav-content .ant-menu .anticon-lock {
  margin-left: 4px;
  font-size: 14px;
  line-height: 14px;
  vertical-align: middle;
  color: yellow;
}
aside.main-nav-layout .main-nav-content .ant-menu .anticon-lock svg {
  width: 14px;
  height: 14px;
}
aside.main-nav-layout .actions-separator {
  color: white;
  min-height: 1px;
  background-color: white;
}
aside.main-nav-layout .actions-separator:hover {
  background-color: white;
}
aside.main-nav-layout .open-close-btn {
  width: 100%;
  height: 50px;
  background-color: #0035b0;
  background-image: url("/icons/chevron-left.svg");
  background-repeat: no-repeat;
  background-size: 22px 22px;
  background-position: center center;
  border: 0;
  font-size: 16px;
  line-height: 24px;
  color: #ffffff;
  cursor: pointer;
}
aside.main-nav-layout .open-close-btn.active {
  background-image: url("/icons/chevron-right.svg");
}
aside.main-nav-layout .logout {
  color: white;
}
aside.main-nav-layout .logout:hover {
  background-color: #0035b0;
}
aside.main-nav-layout .logout .logout-text {
  padding-left: 11px;
}
aside.main-nav-layout .logout img {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-left: 23px;
  width: 24px;
  height: 24px;
  content: url("/icons/logout.png");
}
aside.main-nav-layout .logout img:hover {
  cursor: pointer;
}
aside.main-nav-layout .logout img.collapsed {
  margin-left: 16px;
}
aside.main-nav-layout .mmx-link {
  display: block;
  color: #fff;
  font-weight: bold;
  width: 100%;
  margin-left: 24px;
}
@media screen and (max-width: 1170px) {
  aside.main-nav-layout .main-nav-content .logo-btn {
    display: none;
  }
}
.app-layout-container {
  width: 100%;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.app-layout-container.is-sidebar-collapsed .main-nav-layout {
  width: 60px;
}
.app-layout-container.is-sidebar-collapsed .main-content-layout {
  width: -webkit-calc(100% - 60px);
  width: calc(100% - 60px);
}
.app-layout-container .main-nav-layout {
  width: 300px;
}
.app-layout-container .main-content-layout {
  width: -webkit-calc(100% - 300px);
  width: calc(100% - 300px);
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: column wrap;
          flex-flow: column wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: stretch;
          align-items: stretch;
  overflow-y: hidden;
  position: relative;
}
.app-layout-container .main-nav-layout + .main-content-layout {
  background-color: #F1F2F5;
}
@media screen and (max-width: 1170px) {
  .app-layout-container.is-sidebar-collapsed .main-nav-layout {
    width: 0;
  }
  .app-layout-container.is-sidebar-collapsed .main-content-layout {
    width: 100%;
  }
  .app-layout-container .main-nav-layout {
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 130px;
  }
  .app-layout-container .main-content-layout {
    width: 100%;
  }
}
.filters-block {
  min-height: 70px;
}
.filters-block select {
  width: 100%;
}
.filters-block .split-fields {
  padding: 0 10px;
}
.filters-block .filters-block-fields {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.filters-block .filters-block-fields .field {
  margin-right: 30px;
  margin-bottom: 15px;
}
.filters-block .filters-block-fields .field.inp {
  width: 100%;
  max-width: 300px;
}
.filters-block .btns-block {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.modal-upgrade-tier {
  padding: 0 36px;
  position: relative;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.pop-count-chart-modal img {
  height: 160px;
}
.pop-count-chart-modal h1 {
  font-size: 2.125em;
}
.pop-count-chart-modal .stats-box {
  border: 1px solid #DDD;
  border-radius: 8px;
  padding: 8px;
  text-align: center;
  background-color: #004CFE;
  min-height: 128px;
  color: white;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.pop-count-chart-modal .stats-box label {
  font-weight: 500;
  font-size: 1.125em;
}
.pop-count-chart-modal .stats-box div > div {
  font-size: 2em;
  font-weight: 600;
}
@media (max-width: 540px) {
  .pop-count-chart-modal .stats-box div > div {
    font-size: 1.5em;
  }
}
.pop-count-chart-modal .description-box {
  display: inline-block;
  vertical-align: top;
  padding-left: 20px;
}
.pop-count-chart-modal .description-box div.description {
  font-size: 1.375em;
}
.pop-count-chart-modal .description-box .grade {
  font-size: 1.75em;
  font-weight: 700;
  color: #004CFE;
  vertical-align: middle;
}
.pop-count-chart-modal .description-box .rc-token {
  font-size: 16px;
  background-color: #FFD966;
  border: 1px solid #BF900C;
  color: black;
  font-weight: 500;
  padding: 2px 8px;
  margin-left: 12px;
  vertical-align: middle;
  display: inline-block;
  line-height: 1em;
  -webkit-transform: translateY(1px);
          transform: translateY(1px);
}
@media (min-width: 540px) {
  .pop-count-chart-modal .description-box div.description.-mb {
    margin-bottom: 12px;
  }
}
@media (max-width: 540px) {
  .pop-count-chart-modal .description-box div.description {
    font-size: 1.25em;
  }
  .pop-count-chart-modal .description-box .grade {
    font-size: 1.5em;
  }
}
@media (max-width: 540px) {
  .pop-count-chart-modal img {
    height: 120px;
  }
  .pop-count-chart-modal h1 {
    font-size: 1.5em;
  }
}
@media (max-width: 768px) {
  .pop-count-chart-modal .stats-box {
    margin-top: 12px;
  }
}
.card-block {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  font-size: 12px;
  line-height: 18px;
}
.card-block.centered {
  -webkit-justify-content: center;
          justify-content: center;
}
.card-block.centered .card-block-data {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.card-block.compact .card-block-img {
  min-width: 75px;
  width: 75px;
}
.card-block.compact .card-block-img .img-container img {
  width: 100%;
  height: auto;
}
.card-block .card-block-img {
  min-width: 90px;
  width: 90px;
}
.card-block .card-block-img .img-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.card-block .card-block-img .img-container img {
  height: 120px;
  cursor: pointer;
}
.card-block .card-block-img .action-button-container {
  min-width: 100%;
  min-height: 24px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 3px 0 0 0;
}
.card-block .card-block-img .action-button-container button,
.card-block .card-block-img .action-button-container a {
  padding: 0!important;
}
.card-block .card-block-data {
  margin-left: 20px;
}
.card-block .card-block-data .player {
  margin: 0 0 5px 0;
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
}
.card-block .card-block-data .grade.badge span {
  background: #004CFE;
  border-radius: 5px;
  padding: 3px 5px;
  font-size: 10px;
  line-height: 25px;
  color: white;
}
.add-manual-card .upload-files-block {
  text-align: right;
}
.add-manual-card .upload-files-block .ant-upload-list {
  text-align: left;
}
.add-manual-card .upload-files-block .ant-upload-list-picture .ant-upload-list-item-thumbnail {
  width: auto;
}
.add-manual-card .upload-files-block .ant-upload-list-picture .ant-upload-list-item-thumbnail img {
  width: auto;
}
.add-manual-card .upload-files-block .ant-upload-list-picture-card .ant-upload-list-item-thumbnail {
  width: auto;
}
.add-manual-card .upload-files-block .ant-upload-list-picture-card .ant-upload-list-item-thumbnail img {
  width: auto;
}
.search-item {
  display: -webkit-flex;
  display: flex;
}
.search-item .details {
  padding: 4px 8px;
}
.search-item .details .rookie-card-token {
  position: relative;
  top: 0 !important;
  right: 0;
  margin-left: 4px;
}
.page-mycollection-mass-import {
  padding: 24px 24px 45px 24px;
}
.page-mycollection-mass-import .icon {
  margin: 30px 0;
  color: #0035b0;
  font-size: 150px;
}
.page-mycollection-mass-import .step1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.page-mycollection-mass-import .step1 button {
  width: 200px;
}
.page-mycollection-mass-import .step1 button.got-copy-btn {
  margin-top: 15px;
  margin-bottom: 15px;
}
.page-mycollection-mass-import .tutorial {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 800px;
}
.page-mycollection-mass-import .step2 .continueButton {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10px 0;
}
.page-mycollection-mass-import .step3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.page-mycollection-mass-import .step3 h2:before {
  content: none;
}
.page-mycollection-mass-import .step3 .buttonGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.page-mycollection-mass-import .step3 .buttonGroup .redirectButton {
  margin-bottom: 40px;
  width: 200px;
}
.page-mycollection-price-movements .ant-tabs > .ant-tabs-nav {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}
.page-mycollection-price-movements .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
  -webkit-flex: none;
          flex: none;
  margin-right: 20px;
}
.page-manage-admin-photos .page-sidebar {
  padding: 12px;
}
.page-manage-admin-photos .page-sidebar .filter-block {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  margin-bottom: 20px;
}
.page-manage-admin-photos .page-sidebar .empty-data-tip {
  margin-bottom: 8px;
  padding: 8px;
  text-align: center;
}
.page-manage-admin-photos .ant-tabs > .ant-tabs-nav .ant-tabs-extra-content button {
  margin-left: 10px;
}
.user-stats-btn {
  background-image: -webkit-linear-gradient(left, darkorange 0%, white 50%, darkorange 100%);
  background-image: linear-gradient(to right, darkorange 0%, white 50%, darkorange 100%);
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.85);
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.user-stats-btn:hover,
.user-stats-btn:focus {
  background-image: -webkit-linear-gradient(left, darkorange 0%, white 50%, darkorange 100%);
  background-image: linear-gradient(to right, darkorange 0%, white 50%, darkorange 100%);
  font-weight: bold;
  border: 1px solid rgba(0, 0, 0, 0.85);
  color: black;
  opacity: 0.7;
}
.toggle {
  display: -webkit-flex;
  display: flex;
  grid-gap: 4px;
  gap: 4px;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.toggleButton {
  font-size: 20px;
  padding-top: 1px;
}
.collectibleGroups {
  overflow-y: auto;
  max-height: 500px;
  margin-top: 10px;
}
.collectibleGroupHeader {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-top: 4px;
  padding-left: 4px;
  padding-right: 8px;
  background: #9691918a;
}
.collectibleGroupHeader > h1 {
  font-size: 14px;
  color: white;
  font-weight: bold;
}
.collapseIcon {
  margin-top: 4px;
}
.collectibleGroupHeaderVisible {
  background: #0169FC;
}
.collectible {
  display: -webkit-flex;
  display: flex;
  cursor: pointer;
}
.collectible:hover {
  background: #5796fa4d;
}
.collectible.selected {
  background-color: #5796fa4d;
}
.collectible .open-collectible-link {
  margin-top: 2px;
}
.collectible .collectible-title {
  margin-top: 6px;
}
.page-manage-admin-photos .savedImage {
  width: 100%;
  max-width: 400px;
}
.photoManager .addPhoto {
  display: -webkit-flex;
  display: flex;
  border: solid 2px #6b6b6b;
  height: 120px;
  width: 90px;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  cursor: pointer;
}
.photoManager .savedImage {
  height: 120px;
  width: 90px;
}
.photoManager .uploadWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.photoManager .photoActions {
  display: -webkit-flex;
  display: flex;
  grid-gap: 8px;
  gap: 8px;
}
.photoManager .photoDetails {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding-left: 8px;
}
.photoManager .photoDescription > * {
  font-size: 14px;
  font-weight: bolder;
  margin-bottom: 0;
}
.photoManager .photoListing {
  margin-top: 10px;
}
.photoManager .photoListing .header {
  background: #d9d9d9;
  padding: 0;
}
.photoManager .photoListing .header > h1 {
  color: #1b1b1b;
  font-weight: bolder;
  font-size: 20px;
  padding: 8px;
  line-height: 1;
  text-transform: uppercase;
}
.photoManager .photoListing .photoListItem {
  display: -webkit-flex;
  display: flex;
}
.photoManager .photoListing .photoListItem div {
  padding: 0 10px;
}
.photoManager .photoListing .photoListItem .imageContainer {
  cursor: pointer;
  width: 110px;
}
.photoManager .photoListing .photoListItem .imageContainer {
  width: 110px;
}
.photoManager .photoListing .photoListItem .titleContainer {
  width: -webkit-calc(100% - 220px);
  width: calc(100% - 220px);
  font-weight: bold;
}
div.lambda-job-tab .ant-checkbox-group-item {
  display: block;
  margin-right: 0;
}
div.lambda-job-tab button,
div.lambda-job-tab .ant-select,
div.lambda-job-tab .ant-input {
  display: block;
  margin: 8px 0;
}
div.lambda-job-tab label:not(.ant-checkbox-wrapper) {
  font-weight: 700;
  display: block;
}
div.lambda-job-tab .response {
  background-color: #EEE;
  border-radius: 8px;
}
div.lambda-job-tab .ant-col {
  padding: 8px;
}
.player-charts-page .main-table thead > tr > th:last-child {
  text-align: right;
}
.player-charts-page .main-table tbody > tr > td:last-child .card-block {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse;
}
.player-charts-page .main-table tbody > tr > td:last-child .card-block .card-block-img {
  margin-right: 0px;
  margin-left: 20px;
}
.player-charts-page .main-table tbody > tr > td:last-child .card-block .card-block-data {
  margin-left: 0px;
  text-align: right;
}
.player-charts-page .main-table .card-block .card-block-data .player.green,
.player-charts-page .main-table .card-block .card-block-data .set.green,
.player-charts-page .main-table .card-block .card-block-data .grade.green {
  font-weight: bold;
  color: green;
}
.player-charts-page .main-table .card-block .card-block-data .player.red,
.player-charts-page .main-table .card-block .card-block-data .set.red,
.player-charts-page .main-table .card-block .card-block-data .grade.red {
  font-weight: bold;
  color: red;
}
.player-charts-page .main-table .card-block .card-block-data .variation.green span {
  font-weight: bold;
  color: green;
}
.player-charts-page .main-table .card-block .card-block-data .variation.red span {
  font-weight: bold;
  color: red;
}
.page-mass-import-transactions {
  padding: 24px 24px 45px 24px;
}
.page-mass-import-transactions .icon {
  margin: 30px 0;
  color: #0035b0;
  font-size: 150px;
}
.page-mass-import-transactions .step1 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.page-mass-import-transactions .step1 button {
  width: 200px;
}
.page-mass-import-transactions .step1 button.got-copy-btn {
  margin-top: 15px;
  margin-bottom: 15px;
}
.page-mass-import-transactions .tutorial {
  text-align: center;
  font-size: 18px;
  margin-bottom: 30px;
  max-width: 800px;
}
.page-mass-import-transactions .step2 .continueButton {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin: 10px 0;
}
.page-mass-import-transactions .step3 {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.page-mass-import-transactions .step3 h2:before {
  content: none;
}
.page-mass-import-transactions .step3 .buttonGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
}
.page-mass-import-transactions .step3 .buttonGroup .redirectButton {
  margin-bottom: 40px;
  width: 200px;
}
.excluded-cards .similarity_succes {
  background-color: #004CFE;
}
.excluded-cards .similarity_warning {
  background-color: #faad14;
}
.excluded-cards .similarity_error {
  background-color: #f5222d;
}
.excluded-cards .no_similarity {
  background-color: white;
}
.sci-check-box {
  width: 32px;
  height: 32px;
  display: inline-block;
}
.sci-check-box .control {
  font-family: arial;
  display: block;
  position: relative;
  padding-left: 28px;
  margin-bottom: 5px;
  padding-top: 3px;
  cursor: pointer;
  font-size: 16px;
}
.sci-check-box .control input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.sci-check-box .control_indicator {
  position: absolute;
  top: 3px;
  left: 0;
  height: 30px;
  width: 31px;
  background: #ffffff;
  border: 1px solid #60749C;
  border-radius: 5px;
}
.sci-check-box .control input:checked ~ .control_indicator {
  background: #0349f8;
}
.sci-check-box .control input:disabled ~ .control_indicator {
  background: #ffffff;
  opacity: 1;
  pointer-events: none;
}
.sci-check-box .control_indicator:after {
  -moz-box-sizing: unset;
       box-sizing: unset;
  content: '';
  position: absolute;
  display: none;
}
.sci-check-box .control input:checked ~ .control_indicator:after {
  display: block;
}
.sci-check-box .control-checkbox .control_indicator:after {
  left: 11px;
  top: 5px;
  width: 4px;
  height: 12px;
  border: solid #ffffff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}
.sci-check-box .control-checkbox input:disabled ~ .control_indicator:after {
  border-color: #ffffff;
}
.collapsible {
  overflow-y: hidden;
  max-height: 0;
  -webkit-transition: max-height 0.3s ease-in-out;
  transition: max-height 0.3s ease-in-out;
  margin-bottom: 4px;
}
.collapsible-visible {
  max-height: 100vh;
}
.cards-under-comps .title {
  text-align: left;
  margin-top: 0.5em;
  font-size: 32px;
  font-weight: bold;
}
.cards-under-comps .introductory-message {
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 5px;
}
.cards-under-comps .text-area {
  max-width: 100%;
  border-radius: 6px;
  resize: none;
}
.cards-under-comps .no-results {
  text-align: center;
  width: 100%;
  font-size: 18px;
  color: gray;
  border: 1px solid #d6d6d6;
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 5px;
}
.cards-under-comps .load-more-button {
  border-radius: 4px;
  color: white;
  font-size: 12px;
  border-color: #777777 !important;
  background: #afafb7 !important;
}
.cards-under-comps .deals-section .div-hidden {
  display: none;
  visibility: hidden;
}
.cards-under-comps .deals-section .div-visible {
  display: unset;
  visibility: visible;
}
.cards-under-comps .deals-section .section-title {
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
  vertical-align: bottom;
}
.cards-under-comps .deals-section .section-title-super {
  vertical-align: super;
  font-size: 14px;
  margin-left: 2px;
}
.card-deal-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  font-size: 12px;
  line-height: 18px;
  border-bottom: 1px solid #F0F0F0;
  margin-bottom: 12px;
  padding-bottom: 12px;
}
.card-deal-container .card-deal-block-img {
  min-width: 100px;
  width: 100px;
}
.card-deal-container .card-deal-block-img .img-container {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: center;
          justify-content: center;
}
.card-deal-container .card-deal-block-img .img-container img {
  height: 140px;
  max-width: 100px;
  cursor: pointer;
}
.card-deal-container .card-deal-block-data .player {
  font-size: 18px;
  font-weight: bold;
  line-height: 18px;
  color: #4A4A4A;
}
.card-deal-container .card-deal-block-data .card-deal-data {
  font-size: 12px;
  line-height: 18px;
  color: #4A4A4A;
}
.card-deal-container .card-deal-block-data .card-deal-ebay-button {
  font-weight: bold;
  border-radius: 4px;
  padding: 5px 40px;
  color: white;
  margin-top: 18px;
  border-color: #0169FC !important;
  background: #0169FC !important;
}
.card-deal-container .card-deal-block-data .card-deal-price-guide {
  cursor: pointer;
  margin-left: 18px;
}
.card-deal-container .card-deal-block-data .card-deal-price-guide .card-deal-price-guide-text {
  color: #4A4A4A;
  font-size: 16px;
  margin-left: 4px;
}
.card-deal-container .card-deal-price-block {
  text-align: right;
}
.card-deal-container .card-deal-price-block .card-price {
  color: #4A4A4A;
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 5px;
}
.card-deal-container .card-deal-price-block .card-price-percentage {
  color: #0DAA00;
  font-size: 16px;
  margin-bottom: 12px;
}
.card-deal-container .card-deal-price-block .card-last-sold {
  color: #979797;
  font-size: 14px;
}
.active-stats {
  display: -webkit-flex;
  display: flex;
}
.active-stats .dd-box {
  margin: 20px;
  padding: 20px;
  width: 50%;
}
.active-stats .dd-box .estimatedContainer .estimatedContainerRow {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 10px;
}
.active-stats .dd-box .estimatedContainer .estimatedContainerRow div {
  -webkit-flex: 0.333333% 1;
          flex: 0.333333% 1;
}
.active-stats .dd-box .estimatedContainer .estimatedContainerRow.header {
  background-color: lightgrey;
  margin-bottom: 20px;
}
.active-stats .title {
  font-size: 18px;
  font-weight: 600;
  padding-left: 16px;
  display: inline-block;
}
.data-dashboard-auction-status {
  padding: 0 16px;
  min-height: 200px;
}
.data-dashboard-auction-status .top-container {
  display: -webkit-flex;
  display: flex;
}
.data-dashboard-auction-status .pagination-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}
.data-dashboard-auction-status .info-section {
  display: -webkit-flex;
  display: flex;
}
.data-dashboard-auction-status .info-section .left-side {
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-flex: 1 1 2;
          flex: 1 1 2;
  padding: 20px;
}
.data-dashboard-auction-status .info-section .right-side {
  padding: 20px;
  min-width: 100px;
  -webkit-flex: 1 1;
          flex: 1 1;
}
.data-dashboard-auction-status .info-section .right-side img {
  width: 100%;
}
aside,
section {
  display: block;
}
.main-content-layout .main-content-container {
  width: 100%;
  min-height: -webkit-calc(100% - 60px);
  min-height: calc(100% - 60px);
  height: -webkit-calc(100% - 60px);
  height: calc(100% - 60px);
  overflow-y: scroll;
}
.main-content-layout .main-nav-container {
  width: 100%;
  height: 60px;
  background-color: #0035b0;
  display: -webkit-flex;
  display: flex;
  position: relative;
  top: 0px;
  z-index: 1000;
}
.main-content-layout .main-nav-container .dropdown-main-container {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 200px;
  height: 60px;
  -webkit-align-items: center;
          align-items: center;
  margin-left: 10px;
  cursor: pointer;
}
.main-content-layout .main-nav-container .dropdown-main-container .selected-option {
  font-size: 20px;
  margin-left: 10px;
}
.main-content-layout .main-nav-container .shadowContainer {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
}
.main-content-layout .main-nav-container .dropdown-container {
  position: absolute;
  left: 0;
  background-color: #0035b0;
  width: 220px;
  z-index: 1;
}
.main-content-layout .main-nav-container .dropdown-container .selected {
  background-color: #004cfe;
}
.main-content-layout .main-nav-container .dropdown-container .option {
  font-size: 16px;
  padding: 10px 20px;
  cursor: pointer;
}
.main-content-layout .main-nav-container .dropdown-container .option:hover {
  background-color: #195dff;
}
.main-content-layout .main-nav-container .divider {
  height: 60px;
  margin: 0 10px;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.3);
}
.main-content-layout .main-nav-container .header-search-trigger {
  width: 640px;
  margin-top: 10px;
  margin-left: 10px;
}
.main-content-layout .main-nav-container .pcc-link {
  color: white;
  margin: auto 0 auto 20px;
  line-height: 16px;
}
.main-content-layout .main-nav-container .mmx-link {
  color: white;
  margin: auto 20px auto 20px;
  line-height: 16px;
}
a {
  color: #004CFE;
  text-decoration: none;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  -webkit-text-decoration-skip: objects;
}
a:hover {
  color: #2970ff;
}
a:active {
  color: #003ad9;
}
a:active,
a:hover {
  text-decoration: none;
  outline: 0;
}
a:focus {
  text-decoration: none;
  outline: 0;
}
a[disabled] {
  color: rgba(0, 0, 0, 0.25);
  cursor: not-allowed;
}
@-webkit-keyframes slidein {
  from {
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
@keyframes slidein {
  from {
    -webkit-transform: scale(1, 0);
            transform: scale(1, 0);
  }
  to {
    -webkit-transform: scale(1, 1);
            transform: scale(1, 1);
  }
}
.backgroundContainer {
  width: -webkit-calc(100% - 300px);
  width: calc(100% - 300px);
  height: 100vh;
  position: fixed;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
  margin-left: 300px;
  opacity: 1;
  -webkit-transition: all 200ms ease-in-out;
  transition: all 200ms ease-in-out;
  cursor: default;
}
.collapsed {
  width: -webkit-calc(100% - 60px);
  width: calc(100% - 60px);
  margin-left: 60px;
}
.listContainer {
  height: -webkit-calc(100% - 40px);
  height: calc(100% - 40px);
  margin-top: 20px;
  overflow-y: scroll;
}
.sci-overlay-search-container {
  width: 640px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
  z-index: 1001;
  margin-left: 10px;
}
.sci-overlay-search-container input[type="text"] {
  width: inherit;
}
.sci-overlay-search-container .clear-search-btn {
  width: 16px;
  height: 16px;
  background-color: #6b6b6b;
  background-image: url("/x_white.svg");
  background-size: 12px 12px;
  background-repeat: no-repeat;
  background-position: center;
  border: 0;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  right: 12px;
  margin-top: -8px;
  z-index: 1001;
  cursor: pointer;
  opacity: 0.8;
  -webkit-transition: opacity 0.3s;
  transition: opacity 0.3s;
}
.sci-overlay-search-container .clear-search-btn:hover {
  opacity: 1;
}
.sci-overlay-search {
  height: 40px;
  font-size: 1.25em;
}
.sci-overlay-search.ant-input-affix-wrapper {
  padding: 0 12px;
  margin: 0px;
  border-color: #ff781f;
  box-shadow: 0 0 0 2px #ff781f;
  border: none;
}
.sci-overlay-search .ant-input-prefix {
  margin-right: 8px;
}
.centeredContainer {
  border-radius: 10px;
  background-color: white;
  height: 80%;
  position: relative;
  padding: 20px 10px 20px 20px;
  color: black;
  box-shadow: 5px 5px 5px #666;
  width: 640px;
  max-width: 90%;
  overflow: hidden;
  -webkit-transform-origin: top;
          transform-origin: top;
  -webkit-animation-duration: 0.25s;
          animation-duration: 0.25s;
  -webkit-animation-name: slidein;
          animation-name: slidein;
  /* width */
  /* Track */
  /* Handle */
}
.centeredContainer ::-webkit-scrollbar {
  width: 8px;
}
.centeredContainer ::-webkit-scrollbar-track {
  background: #f1f1f1;
}
.centeredContainer ::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ccc;
}
.centeredContainer ::-webkit-scrollbar-thumb :hover {
  background: #999;
}
.centeredContainer .ant-input-affix-wrapper {
  padding: 0px 12px;
  margin: 0px;
}
.centeredContainer .ant-input-affix-wrapper:hover {
  border-color: #ff781f;
}
.centeredContainer .ant-input-affix-wrapper:focus {
  box-shadow: 0 0 0 2px #ff781f;
  border: none;
}
.centeredContainer .ant-input-affix-wrapper .ant-input-prefix {
  margin-right: 8px;
}
.centeredContainer .ant-input-affix-wrapper .ant-input-suffix .ant-input-clear-icon {
  font-size: 16px;
}
.centeredContainer .ant-input-affix-wrapper.ant-input-affix-wrapper-focused {
  border-color: #ff781f;
  box-shadow: 0 0 0 1px #ff781f;
}
.centeredContainer .ant-input-suffix > i,
.centeredContainer .ant-input-suffix > span {
  font-size: 16px;
}
.centeredContainer #sci-overlay-search {
  height: 40px;
  font-size: 1.25em;
}
.centeredContainer #sci-overlay-search:hover {
  border-color: #ff781f;
}
.centeredContainer #sci-overlay-search:focus {
  box-shadow: none;
  border: none;
}
.centeredContainer .compare-col {
  display: block;
  text-align: right;
  width: 100%;
  padding-right: 5%;
  font-size: 12px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  font-weight: 600;
  color: #444;
}
.centeredContainer .header {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
}
.centeredContainer .header .closeIcon {
  color: #666;
  -webkit-filter: none;
          filter: none;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  margin-left: 5px;
}
.centeredContainer .listItem {
  cursor: pointer;
  padding: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  border: none !important;
}
.centeredContainer .listItem:nth-child(2n) {
  background-color: rgba(0, 0, 0, 0.03);
}
.centeredContainer .listItem:hover {
  background-color: rgba(0, 53, 176, 0.1);
}
.centeredContainer .listItem .cardImage {
  height: 98px;
  width: 70px;
  -webkit-object-fit: cover;
          object-fit: cover;
  margin-right: 20px;
}
.centeredContainer .listItem .description {
  font-size: 1.25em;
  white-space: normal;
  white-space: initial;
  padding-right: 20px;
}
.centeredContainer .listItem div.checkbox {
  width: 15%;
  max-width: 100px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.centeredContainer .listItem div.checkbox img {
  width: 40%;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #004af8 !important;
  border-color: #004af8 !important;
}
.ant-checkbox-disabled.ant-checkbox-checked .ant-checkbox-inner:after {
  border-color: #ffffff;
}
@media screen and (max-width: 1170px) {
  .backgroundContainer {
    margin-left: 0;
    width: 100%;
  }
  .centeredContainer {
    margin: 10% auto;
    height: 80%;
  }
  .cardImage {
    margin-right: 10px;
  }
}
@media screen and (min-width: 1170px) {
  .backgroundContainer {
    background-color: rgba(0, 0, 0, 0);
  }
  .centeredContainer {
    position: absolute;
    top: 52px;
    left: 240px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }
  .centeredContainer .header {
    display: none;
  }
}
.ant-menu-inline-collapsed-tooltip {
  display: none;
}
.menuItemContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
section.ant-layout {
  background: transparent;
}
.ant-layout-header {
  position: -webkit-sticky;
}
.ant-menu-submenu-popup .ant-menu-item a {
  color: white;
}
.ant-menu-submenu-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background-color: #0135af;
}
.app-content {
  width: 100%;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  padding: 25px;
}
@media (max-width: 768px) {
  .app-content {
    padding: 24px 30px;
  }
}
@media (max-width: 650px) {
  .app-content {
    padding: 24px 16px;
  }
}
.app-content-padded {
  width: 100%;
  overflow: auto;
  padding: 24px 24px 45px;
  margin-bottom: 50px;
  -webkit-overflow-scrolling: touch;
}
@media (max-width: 650px) {
  .app-content-padded {
    padding: 24px 15px;
  }
}
h2 {
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: -0.02em;
  font-size: 18px;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  position: relative;
}
h2:before {
  content: "";
  width: 8px;
  height: 8px;
  background: -webkit-linear-gradient(#0081ff 0%, #004cfd 100%);
  background: linear-gradient(#0081ff 0%, #004cfd 100%);
  -webkit-filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
          filter: drop-shadow(0px 3px 6px rgba(0, 0, 0, 0.16));
  display: inline-block;
  border-radius: 100%;
  margin-right: 8px;
}
h2.no-dot {
  margin-bottom: 0;
}
h2.no-dot:before {
  content: none;
}
.dashboard-page .ant-card-bordered {
  border: 0 !important;
}
.dashboard-page .ant-card-meta {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}
.dashboard-page .ant-card-meta-description {
  color: black !important;
}
.dashboard-page .ant-card-meta-title {
  font-weight: bold;
}
.dashboard-page .ant-card {
  margin-bottom: 30px;
  max-width: 100% !important;
}
.dashboard-page .ant-card-meta-avatar {
  width: 82px;
  margin-bottom: 1rem;
}
.sider-search {
  font-size: 12px;
  margin: 0;
  margin-left: 5px;
}
.ant-layout-sider-zero-width-trigger {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-transform: rotate(-90deg) translateX(-50%);
          transform: rotate(-90deg) translateX(-50%);
  background-color: #404cfe;
  width: auto;
  height: 26px;
  top: 10px;
  right: -48px;
  box-shadow: 1px 1px 3px 1px rgba(0, 23, 84, 0.15);
  z-index: 100;
  padding: 8px;
}
.ant-layout-sider-zero-width-trigger:hover {
  background-color: #1000fe;
}
.ant-layout-sider-zero-width-trigger.collapsed {
  right: -50px;
}
@media (max-width: 1024px) {
  .ant-layout-sider-zero-width-trigger {
    right: -60px;
  }
  .ant-layout-sider-zero-width-trigger.collapsed {
    right: -57px;
  }
}
.ant-layout-sider {
  border-right: 1px solid #ccc;
}
.ant-layout-sider-children {
  overflow-y: auto;
}
.ant-layout-sider-collapsed {
  border-right: 0;
}
aside.ant-layout-sider {
  position: -webkit-sticky;
  position: sticky;
  top: 65px;
  z-index: 500;
}
.recharts-legend-wrapper {
  overflow-y: auto;
  -webkit-overflow-scrolling: auto;
  /* !important is needed sometimes */
  /* Track */
  /* Handle */
}
.recharts-legend-wrapper::-webkit-scrollbar {
  width: 12px !important;
}
.recharts-legend-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3) !important;
  border-radius: 10px !important;
}
.recharts-legend-wrapper::-webkit-scrollbar-thumb {
  border-radius: 10px !important;
  background: #41617d !important;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.5) !important;
}
.recharts-legend-wrapper::-webkit-scrollbar-thumb:window-inactive {
  background: #41617d !important;
}
.recharts-responsive-container {
  background: white;
}
@media (max-width: 768px) {
  .recharts-tooltip-wrapper {
    max-width: 280px;
  }
}
@media (max-width: 650px) {
  .recharts-tooltip-wrapper {
    max-width: 200px;
  }
}
.ant-collapse-no-arrow .ant-collapse-header {
  display: none;
}
.ant-collapse-no-arrow .ant-collapse-content {
  background-color: #e8e8e8;
  border-top: 0;
}
li.ant-menu-item:hover,
li.ant-menu-submenu:hover,
.ant-menu-horizontal > li.ant-menu-item-selected {
  background-color: #0135af;
}
.ant-menu-submenu > .ant-menu {
  background-color: #004cfe !important;
}
@media (max-width: 1169px) {
  .ant-menu-submenu > .ant-menu {
    background-color: transparent;
  }
}
body {
  background-color: #f3f7fd !important;
  font-family: "Ubuntu", sans-serif !important;
}
.positive-num {
  color: green;
}
.negative-num {
  color: red;
}
.ant-menu-horizontal {
  border-bottom: 0 !important;
}
.alerts .ant-list-header {
  background: #404cfe;
  color: white;
  border-bottom: 0 !important;
}
.alerts .ant-list-items {
  background: #e2e3ff;
}
/* Make clicks pass-through */
#nprogress {
  pointer-events: none;
}
#nprogress .bar {
  background: #000;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px;
}
/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #29d, 0 0 5px #29d;
  opacity: 1;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px);
}
/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px;
}
#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #fff;
  border-left-color: #fff;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite;
}
.nprogress-custom-parent {
  overflow: hidden;
  position: relative;
}
.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute;
}
@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}
.ant-layout.ant-layout-has-sider aside.ant-layout-sider {
  background: #e5ecf8;
  top: 0 !important;
}
.ant-picker-input > input {
  text-align: center;
}
.card-filter-wizard {
  padding: 0 !important;
}
.card-filter-wizard .ant-collapse-no-arrow .ant-collapse-content {
  background: #e5ecf8;
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #9cacc7;
  border-bottom: 1px solid #9cacc7;
}
.card-filter-wizard .ant-collapse {
  border-radius: 0;
  border-left: 0;
  border-right: 0;
  border-top: 1px solid #9cacc7;
  border-bottom: 1px solid #9cacc7;
  background: white;
  -webkit-filter: drop-shadow(1px 2px 6px rgba(156, 172, 199, 0.4));
          filter: drop-shadow(1px 2px 6px rgba(156, 172, 199, 0.4));
}
.card-filter-wizard .ant-collapse ul li,
.card-filter-wizard .ant-collapse label {
  color: #60749b;
}
.card-filter-wizard .ant-picker {
  padding: 16px;
  margin-bottom: 0 !important;
  background: #DFEAF5;
  -webkit-filter: drop-shadow(1px 2px 6px rgba(156, 172, 199, 0.4));
          filter: drop-shadow(1px 2px 6px rgba(156, 172, 199, 0.4));
}
.card-filter-wizard .ant-picker .ant-picker-input {
  border: 0;
}
.card-filter-wizard .ant-picker input {
  border: 0;
  text-align: center;
  background: white;
}
.card-filter-wizard .ant-typography strong {
  text-transform: uppercase;
  font-size: 18px;
  color: black;
  letter-spacing: -0.02em;
}
.card-filter-wizard .seller-buyer-options {
  background: white;
  border-top: 1px solid #dadada;
  border-bottom: 1px solid #dadada;
}
.transparent {
  opacity: 0.45;
}
.ant-collapse-header {
  text-transform: capitalize;
}
.table-card-stats {
  margin-bottom: 0;
  padding-left: 0;
  list-style: none;
  display: -webkit-flex;
  display: flex;
  text-align: center;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.table-card-stats li {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0 2px;
}
.table-card-stats li:first-of-type {
  -webkit-flex-grow: 0;
          flex-grow: 0;
}
.table-card-stats li div {
  display: block;
  font-size: 14px;
  color: #9cacc7;
}
.table-card-stats li span {
  font-size: 16px;
  font-weight: bold;
  color: black;
}
.table-card-stats .last-sale-date {
  position: absolute;
  max-width: 120px;
  width: 100%;
}
.card-column-stats {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
}
@media (max-width: 1024px) {
  .card-column-stats {
    -webkit-flex-flow: column wrap;
            flex-flow: column wrap;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
}
.card-column-stats > div {
  -webkit-flex-grow: 1;
          flex-grow: 1;
  -webkit-align-self: stretch;
          align-self: stretch;
  width: 100%;
}
.card-column-stats .ant-statistic-content-value-int,
.card-column-stats .ant-statistic-content-value-decimal,
.card-column-stats .ant-statistic-content-prefix {
  font-size: 30px;
  font-weight: bold;
  letter-spacing: -0.02em;
  margin-right: 0;
}
.card-column-stats .ant-statistic-title {
  color: #9cacc7;
}
@media (max-width: 650px) {
  aside:not(.ant-layout-sider-collapsed) + main.ant-layout-content .ant-spin-nested-loading {
    width: 92vw;
  }
}
@media (max-width: 1200px) {
  aside:not(.ant-layout-sider-collapsed) + main.ant-layout-content #chart .ant-tabs-tabpane-active {
    padding-bottom: 80px;
  }
}
.search-btn {
  width: 100%;
  -webkit-flex: 0 1 100%;
          flex: 0 1 100%;
}
@media (max-width: 650px) {
  .search-btn {
    max-width: 100%;
    margin-top: 15px;
  }
}
@media (max-width: 650px) {
  .search-btn-wrapper {
    width: 100%;
  }
}
.excluded td.ant-table-row-cell-break-word {
  background: #dedede;
}
.ant-form-item-label {
  font-size: 15px;
  font-weight: bold;
}
.recharts-legend-item {
  display: -webkit-inline-flex !important;
  display: inline-flex !important;
  -webkit-align-items: center;
          align-items: center;
  margin: 2px;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 1px 2px 10px rgba(96, 116, 155, 0.4);
  -webkit-flex-flow: row nowrap;
          flex-flow: row nowrap;
  height: 120px;
}
@media (hover: hover) {
  .recharts-legend-item:hover {
    background: #ececec;
  }
}
.recharts-legend-item-disabled {
  opacity: 60%;
}
.legend-body-wrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  width: 100%;
  height: 100%;
  -webkit-align-items: center;
          align-items: center;
}
.legend-body-wrapper .card-info {
  width: 100%;
  padding-left: 4px;
  position: relative;
}
@media (max-width: 1024px) {
  .legend-body-wrapper .card-info {
    padding-left: 0;
    height: -webkit-calc(100% - 16px);
    height: calc(100% - 16px);
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
.legend-body-wrapper .legend-text {
  position: relative;
  font-size: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #555;
  padding: 8px 8px 0;
  font-weight: 500;
  line-height: 1.2;
}
.legend-body-wrapper .legend-text.-rc {
  max-width: -webkit-calc(100% - 40px);
  max-width: calc(100% - 40px);
}
@media (max-width: 1024px) {
  .legend-body-wrapper .legend-text {
    padding: 0 8px;
  }
}
@media (max-width: 540px) {
  .legend-body-wrapper .legend-text {
    font-size: 12px;
  }
  .legend-body-wrapper .legend-text.-rc {
    max-width: -webkit-calc(100% - 32px);
    max-width: calc(100% - 32px);
  }
}
.legend-body-wrapper .action-button-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  min-width: 100%;
  min-height: 24px;
  align-self: flex-end;
}
.legend-body-wrapper .action-button-container button {
  width: 100% !important;
  border: 1px solid rgba(196, 196, 196, 0.32) !important;
  border-radius: 0;
  height: 100% !important;
  padding: 12px 0 6px 0 !important;
}
.legend-body-wrapper .action-button-container button i,
.legend-body-wrapper .action-button-container button svg {
  font-size: 18px;
  width: 18px;
  height: 18px;
}
@media (max-width: 1024px) {
  .legend-body-wrapper {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }
  .legend-body-wrapper .action-button-container {
    margin-left: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    min-width: 80px;
    min-height: 115px;
  }
  .legend-body-wrapper .action-button-container button {
    width: 100% !important;
    border: 1px solid rgba(235, 235, 235, 0.32) !important;
    border-radius: 0;
    height: 100% !important;
  }
  .legend-body-wrapper .action-button-container button i,
  .legend-body-wrapper .action-button-container button svg {
    font-size: 24px !important;
  }
}
@media (max-width: 1024px) and (max-width: 540px) {
  .legend-body-wrapper .action-button-container {
    min-width: 65px;
  }
}
@media (max-width: 1024px) and (max-width: 540px) {
  .legend-body-wrapper .action-button-container button i,
  .legend-body-wrapper .action-button-container button svg {
    font-size: 18px !important;
  }
}
.rookie-card-token {
  font-size: 12px;
  position: absolute;
  top: 8px;
  right: 8px;
  background-color: #FFD966;
  border: 1px solid #BF900C;
  color: black;
  font-weight: 500;
  padding: 0 8px;
}
.rookie-card-token.-relative {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.rookie-card-token.-big {
  font-size: 16px;
}
@media (max-width: 1024px) {
  .rookie-card-token {
    top: 0 !important;
    right: 6px;
    padding: 0 4px;
  }
}
.legend-body--middle-row {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
  padding: 0 8px;
}
.legend-body--middle-row div.text {
  color: #444;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
@media (max-width: 768px) {
  .legend-body--middle-row button {
    display: none;
  }
}
.legend-body--middle-row .grade {
  font-size: 16px;
  font-weight: 700;
}
@media (max-width: 1024px) {
  .legend-body--middle-row .grade {
    font-size: 16px;
  }
}
@media (max-width: 768px) {
  .legend-body--middle-row .grade {
    font-size: 14px;
  }
}
.mm-chart-options-popover .ant-menu-submenu-selected:hover {
  background-color: unset;
}
.mm-chart-options-popover .ant-menu-submenu-selected:hover .ant-menu-submenu-title {
  cursor: default;
}
.mm-chart-options-popover .ant-popover-inner-content {
  padding: 0;
}
.mm-chart-options-popover .ant-menu-submenu-arrow {
  opacity: 0;
}
.mm-chart-options-popover .ant-popover-arrow.ant-popover-arrow {
  border-top-color: #004CFE;
  border-left-color: #004CFE;
}
.chart-card-details-block {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}
.chart-card-details-block .chart-card-details-preview {
  min-width: 75px;
  width: 75px;
  margin-right: 15px;
}
.chart-card-details-block .chart-card-details-info {
  width: -webkit-calc(100% - 90px);
  width: calc(100% - 90px);
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
}
.chart-card-details-block .chart-card-details-info.compact {
  -webkit-flex-direction: column;
          flex-direction: column;
}
.chart-card-details-block .chart-card-details-info.compact .action-button-container {
  margin-left: 0;
}
.chart-card-details-block .chart-card-details-info .action-button-container {
  margin-left: 15px;
}
@media (max-width: 768px) {
  .chart-card-details-block .chart-card-details-info.compact {
    -webkit-flex-direction: row;
            flex-direction: row;
  }
  .chart-card-details-block .chart-card-details-info .action-button-container {
    min-width: 80px;
    min-height: 115px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  .chart-card-details-block .chart-card-details-info .action-button-container button {
    width: 100% !important;
    border: 1px solid rgba(235, 235, 235, 0.32) !important;
    border-radius: 0;
    height: 100% !important;
  }
  .chart-card-details-block .chart-card-details-info .action-button-container button i,
  .chart-card-details-block .chart-card-details-info .action-button-container button svg {
    font-size: 24px !important;
  }
}
.legend-image.card {
  width: 75px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.legend-image.card img {
  -webkit-align-self: center;
          align-self: center;
  width: 75px;
  max-height: 115px;
  padding: 5px;
}
.legend-image.not-card {
  width: 80px;
  height: 80px;
  border-radius: 100%;
}
.legend-image {
  display: inline-block;
}
.recharts-default-legend {
  display: grid;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  grid-template-columns: 1fr;
  grid-gap: 10px;
  margin-bottom: 0;
}
@media (max-width: 830px) {
  .recharts-default-legend {
    padding-left: 0;
  }
}
@media (min-width: 830px) {
  .recharts-default-legend {
    grid-template-columns: 1fr 1fr;
  }
}
@media (min-width: 1170px) {
  .recharts-default-legend {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
@media (min-width: 1440px) {
  .recharts-default-legend {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}
@media (min-width: 1401px) AND (max-width: 1650px) {
  aside.ant-layout-sider:not(.ant-layout-sider-collapsed) + .ant-layout-content .recharts-default-legend {
    grid-template-columns: 1fr 1fr 1fr !important;
  }
}
@media (min-width: 1160px) AND (max-width: 1400px) {
  aside.ant-layout-sider:not(.ant-layout-sider-collapsed) + .ant-layout-content .recharts-default-legend {
    grid-template-columns: 1fr 1fr !important;
  }
}
@media (min-width: 0px) AND (max-width: 1200px) {
  aside.ant-layout-sider:not(.ant-layout-sider-collapsed) + .ant-layout-content .recharts-default-legend {
    grid-template-columns: 1fr !important;
  }
}
.myc-group-chart .recharts-default-legend {
  grid-template-columns: 1fr;
  grid-auto-flow: row;
  -webkit-align-content: center;
          align-content: center;
}
.myc-group-chart .recharts-legend-item {
  text-align: unset;
  box-shadow: unset;
  height: unset;
}
.myc-group-chart .recharts-legend-item .recharts-legend-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90% !important;
}
@media (max-width: 768px) {
  .myc-group-chart > .recharts-surface {
    padding-right: unset !important;
  }
  .myc-group-chart .recharts-default-legend {
    width: 90%;
    margin: 0 auto !important;
  }
}
@media (min-width: 768px) AND (max-width: 992px) {
  .myc-group-chart .recharts-default-legend {
    grid-template-rows: 1fr 1fr 1fr 1fr;
    width: 90%;
    margin: 0 auto !important;
  }
}
@media (min-width: 992px) AND (max-width: 1200px) {
  .myc-group-chart .recharts-default-legend {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-auto-flow: unset;
  }
}
.myc-group-chart-sm .recharts-default-legend {
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-flow: column;
  grid-gap: unset;
  -webkit-align-content: center;
          align-content: center;
}
.myc-group-chart-sm .recharts-default-legend .recharts-legend-item {
  text-align: unset;
  box-shadow: unset;
  height: unset;
}
.myc-group-chart-sm .recharts-default-legend .recharts-legend-item .recharts-legend-item-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 135px;
}
@media (min-width: 576px) AND (max-width: 768px) {
  .myc-group-chart-sm .recharts-default-legend .recharts-default-legend {
    height: unset;
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-auto-flow: unset;
  }
  .myc-group-chart-sm .recharts-default-legend .recharts-default-legend .recharts-legend-item-text {
    max-width: 90% !important;
  }
}
@media (max-width: 460px) {
  .myc-group-chart-sm .recharts-default-legend {
    height: unset;
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-auto-flow: unset;
  }
  .myc-group-chart-sm .recharts-default-legend .recharts-legend-item-text {
    max-width: unset !important;
  }
}
@media (min-width: 460px) AND (max-width: 576px) {
  .myc-group-chart-sm .recharts-default-legend {
    height: unset;
  }
}
@media (min-width: 576px) AND (max-width: 768px) {
  .myc-group-chart-sm .recharts-default-legend {
    height: unset;
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-auto-flow: unset;
  }
  .myc-group-chart-sm .recharts-default-legend .recharts-legend-item-text {
    max-width: 90% !important;
  }
}
@media (min-width: 992px) AND (max-width: 1250px) {
  .myc-group-chart-sm .recharts-default-legend {
    grid-template-columns: 1fr;
    grid-template-rows: unset;
    grid-auto-flow: unset;
  }
  .myc-group-chart-sm .recharts-default-legend .recharts-legend-item-text {
    max-width: 90% !important;
  }
}
.ant-table .table-row-selected {
  background-color: pink;
}
.ant-table-body {
  overflow-x: auto !important;
  overflow-y: auto !important;
}
.ant-table .ant-table-scroll:after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  opacity: 0;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.4) 100%);
  -webkit-transition-property: background, width, opacity;
  transition-property: background, width, opacity;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
  pointer-events: none;
}
.ant-table-scroll-position-left .ant-table-scroll:after,
.ant-table-scroll-position-middle .ant-table-scroll:after {
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.4) 100%);
  width: 20px;
  opacity: 1;
}
.ant-table-scroll-position-right .ant-table-scroll:after {
  content: "";
  display: block;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 0;
  opacity: 0;
  background: -webkit-linear-gradient(left, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.4) 100%);
  background: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 30%, rgba(0, 0, 0, 0.25) 70%, rgba(0, 0, 0, 0.4) 100%);
  -webkit-transition-property: background, width, opacity;
  transition-property: background, width, opacity;
  -webkit-transition: 0.25s ease-in-out;
  transition: 0.25s ease-in-out;
}
@media screen and (max-width: 1400px) {
  .ant-table-mobile.small-padding .ant-table-thead > tr > th,
  .ant-table-mobile.small-padding .ant-table-tbody > tr > td {
    padding: 12px 8px;
  }
}
@media screen and (max-width: 768px) {
  .ant-table-mobile.player-charts-cards td:nth-of-type(n + 3) {
    display: none;
  }
  .ant-table-mobile.player-charts-cards td:nth-of-type(n + 2) {
    border-bottom: 3px solid black !important;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10%;
    -webkit-flex-flow: column;
            flex-flow: column;
    -webkit-align-items: flex-start;
            align-items: flex-start;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats .card-stats-label-wrapper {
    width: 100%;
    display: -webkit-inline-flex;
    display: inline-flex;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats .stats-change {
    grid-column: 2/2;
    grid-row: 4;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats .stats-changedollar {
    grid-column: 1/2;
    grid-row: 4;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats .stats-low {
    grid-column: 1;
    grid-row: 2;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats .stats-high {
    grid-column: 1;
    grid-row: 3;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats li {
    display: -webkit-inline-flex;
    display: inline-flex;
    -webkit-align-items: center;
            align-items: center;
    -webkit-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats li .card-stats-label {
    font-size: 12px;
    -webkit-flex: 1 0 auto;
            flex: 1 0 auto;
    font-weight: normal;
    display: -webkit-flex;
    display: flex;
    word-break: normal;
    text-align: left;
    margin-right: 5px;
    white-space: nowrap;
  }
  .ant-table-mobile.player-charts-cards .table-card-stats li .card-stats-label:after {
    content: "";
    width: 100%;
    -webkit-flex-grow: 1;
            flex-grow: 1;
    display: -webkit-inline-flex;
    display: inline-flex;
    border-bottom: 1px dotted;
  }
  .ant-table-mobile .ant-table-fixed {
    display: block !important;
    width: auto !important;
  }
  .ant-table-mobile .ant-table {
    display: block !important;
  }
  .ant-table-mobile .ant-table-thead {
    display: none;
  }
  .ant-table-mobile .ant-table-tbody {
    display: block;
  }
  .ant-table-mobile .ant-table-thead > tr,
  .ant-table-mobile .ant-table-tbody > tr {
    width: 100%;
    display: block;
  }
  .ant-table-mobile .ant-table-thead > tr th:first-of-type,
  .ant-table-mobile .ant-table-tbody > tr th:first-of-type,
  .ant-table-mobile .ant-table-thead > tr td:first-of-type,
  .ant-table-mobile .ant-table-tbody > tr td:first-of-type {
    padding-top: 1rem;
  }
  .ant-table-mobile .ant-table-thead > tr th:last-of-type,
  .ant-table-mobile .ant-table-tbody > tr th:last-of-type,
  .ant-table-mobile .ant-table-thead > tr td:last-of-type,
  .ant-table-mobile .ant-table-tbody > tr td:last-of-type {
    padding-bottom: 1rem;
  }
  .ant-table-mobile .ant-table-thead > tr > th,
  .ant-table-mobile .ant-table-tbody > tr > th,
  .ant-table-mobile .ant-table-thead > tr > td,
  .ant-table-mobile .ant-table-tbody > tr > td {
    display: block;
    width: auto !important;
    padding: 8px 16px;
    border: 0;
    font-size: 1.1rem;
    text-align: left !important;
  }
  .ant-table-mobile .ant-table-thead > tr > th:last-child,
  .ant-table-mobile .ant-table-tbody > tr > th:last-child,
  .ant-table-mobile .ant-table-thead > tr > td:last-child,
  .ant-table-mobile .ant-table-tbody > tr > td:last-child {
    border-bottom: 4px solid black;
  }
  .ant-table-mobile .ant-table-scroll-position-left .ant-table-scroll:after {
    opacity: 0;
  }
  .leader-table .ant-table td:nth-of-type(n + 8) {
    display: none !important;
  }
  .leader-table .ant-table td:nth-of-type(n + 7) {
    border-bottom: 3px solid black !important;
  }
}
.recently-added-cards td p {
  margin-top: 1em;
}
.leader-table .ant-table-thead > tr > th {
  background: -webkit-linear-gradient(#f3f7fd 0%, #e9f0fb 100%);
  background: linear-gradient(#f3f7fd 0%, #e9f0fb 100%);
  color: #60749b;
  font-weight: bold;
}
.alert-action {
  width: auto !important;
  margin-right: 25px;
  float: right;
  color: blue !important;
}
@media (max-width: 768px) {
  .alert-action {
    margin-right: 0;
    float: none;
  }
}
.ant-alert.ant-alert-info {
  background-color: #ffffff;
  border: 1px solid #9cacc7;
}
.ant-alert.ant-alert-info .ant-alert-message {
  font-weight: bold;
}
.ant-card-meta-title {
  text-overflow: unset !important;
  white-space: normal !important;
}
@media (min-width: 768px) {
  .hidden-desktop {
    display: none;
  }
}
@media (max-width: 769px) {
  .hidden-mobile {
    display: none;
  }
}
.inline-table-label {
  font-size: small;
  color: #6b6b6b;
  text-transform: uppercase;
}
td.ant-table-row-cell-break-word {
  color: #1b1b1b;
}
.player-charts-cards .ant-table,
.chart-any-card .ant-table,
.my-collection .ant-table,
.alert-table .ant-table,
.dashboard-page .ant-table-wrapper .ant-table {
  border: 1px solid white;
}
.player-charts-cards .ant-table .ant-table-header,
.chart-any-card .ant-table .ant-table-header,
.my-collection .ant-table .ant-table-header,
.alert-table .ant-table .ant-table-header,
.dashboard-page .ant-table-wrapper .ant-table .ant-table-header {
  background: #e9f0fb;
}
.player-charts-cards .ant-table-thead > tr > th.ant-table-row-cell-break-word,
.chart-any-card .ant-table-thead > tr > th.ant-table-row-cell-break-word,
.my-collection .ant-table-thead > tr > th.ant-table-row-cell-break-word,
.alert-table .ant-table-thead > tr > th.ant-table-row-cell-break-word,
.dashboard-page .ant-table-wrapper .ant-table-thead > tr > th.ant-table-row-cell-break-word,
.player-charts-cards .ant-table-thead > tr > th,
.chart-any-card .ant-table-thead > tr > th,
.my-collection .ant-table-thead > tr > th,
.alert-table .ant-table-thead > tr > th,
.dashboard-page .ant-table-wrapper .ant-table-thead > tr > th {
  background: #e9f0fb;
  font-weight: bold;
  font-size: 12px;
  color: #60749b;
  border-bottom: 0;
}
.player-name {
  font-weight: bold;
  font-size: 14px;
}
.card-meta,
.recharts-tooltip-item-value,
.recharts-tooltip-item-separator {
  font-size: 12px;
}
.recharts-tooltip-item-name {
  display: inline-block;
}
.graph-controls {
  background: white;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 30px 6px 0;
}
.graph-controls .graph-rangepicker > .graph-rangepicker-quick-options {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 8px;
  height: 70%;
}
.graph-controls .graph-rangepicker > .graph-rangepicker-quick-options .ant-btn {
  border: unset !important;
  padding: 0 6px;
}
.graph-controls .graph-rangepicker > .graph-rangepicker-quick-options .ant-btn.ant-btn-text > span {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .graph-controls {
    padding: 0 0 6px;
    -webkit-flex-flow: row nowrap;
            flex-flow: row nowrap;
  }
  .graph-controls .ant-select {
    font-size: 13px !important;
    width: auto !important;
  }
  .graph-controls .ant-select:last-of-type {
    margin-right: 0 !important;
  }
}
.not-fullscreen-btn {
  margin-right: auto;
  position: absolute !important;
  top: 5px;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}
.fullscreen-btn {
  margin-left: auto;
}
.fullscreen-btn + button {
  margin-left: 20px;
}
@media (max-width: 768px) {
  .fullscreen-btn {
    display: none !important;
  }
}
.chart-notfullscreen {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  min-height: 75vh;
}
@media (max-width: 1024px) {
  .chart-notfullscreen {
    max-height: 540px !important;
  }
}
.chart-fullscreen {
  width: 100% !important;
  height: 100% !important;
  min-height: 100vh !important;
  position: fixed;
  z-index: 1000;
  background: white;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  left: 0;
  top: 0;
  bottom: 0;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  padding: 40px;
}
.my-collection-chart .recharts-legend-item {
  height: auto;
}
.my-collection-chart .recharts-legend-item .recharts-surface {
  padding-right: 0 !important;
  margin-left: 10px;
}
.ant-notification {
  z-index: 10000000000009 !important;
}
tr.ant-table-row.ant-table-row-level-1 {
  padding-left: 30px;
  background: rgba(236, 236, 236, 0.44);
}
.ant-table-row-indent.indent-level-1,
.ant-table-row.ant-table-row-level-1 .ant-table-row-expand-icon.ant-table-row-spaced {
  display: none;
}
.ant-table-mobile .ant-table-tbody {
  min-width: 260px !important;
}
/* prevent iphone from zooming in on tapping an input */
.ant-input-search .ant-input,
.live-search .ant-input,
.ant-select {
  font-size: 16px !important;
}
.ant-select,
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 4px;
}
/* Saved Search style */
.SavedSearches {
  overflow-x: unset;
}
.SavedSearches .ant-list-item {
  display: -webkit-flex !important;
  display: flex !important;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  padding: 0 0.5rem;
}
.SavedSearches .ant-list-item a {
  font-weight: bold;
}
.EbayLogo {
  margin-left: -0.75rem;
}
@media (min-width: 768px) {
  .EbayLogo {
    position: relative;
    top: 2rem;
  }
}
.PlayerChartsWarning {
  padding: 1rem 2rem;
  color: #004CFE;
}
.PlayerChartsWarning i,
.PlayerChartsWarning svg {
  color: #004CFE;
}
.ant-collapse-header > div {
  -webkit-align-items: center;
          align-items: center;
}
.yellow {
  color: #eaa221;
}
.blue {
  color: #004CFE;
}
@media (max-width: 650px) {
  .mobileFullFlex {
    display: -webkit-flex;
    display: flex;
    width: 100%;
    -webkit-justify-content: center;
            justify-content: center;
  }
}
@media (max-width: 650px) {
  .mobileNoLeftPadding {
    padding-left: 0;
  }
}
.filter-background {
  padding: 32px;
  margin-top: 22px;
  margin-bottom: 15px;
  background: white;
  border: 1px solid #fff;
  box-shadow: 1px 3px 60px rgba(156, 172, 199, 0.4);
  border-radius: 4px;
}
.filter-background label > span {
  font-weight: bold;
  margin-bottom: 4px;
  display: inline-block;
}
@media (max-width: 650px) {
  .filter-background {
    padding: 16px;
  }
}
.filter-background .ant-row {
  margin-bottom: 16px;
}
.saved-searches div {
  border-radius: 4px;
}
.saved-searches h3 {
  margin-bottom: 0;
}
@media (max-width: 650px) {
  .saved-searches {
    position: relative !important;
  }
}
@media (min-width: 651px) {
  .saved-searches .ant-collapse-item.ant-collapse-item-active {
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.25);
  }
}
.saved-searches .saved-category {
  width: 100%;
}
.saved-searches .add-new-category {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}
.saved-searches .add-new-category input[type="text"] {
  height: 32px;
}
.saved-searches .saved-grid {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 1em 1em;
  gap: 1em 1em;
  margin-bottom: 1em;
}
@media (min-width: 650px) {
  .saved-searches .saved-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (min-width: 990px) {
  .saved-searches .saved-grid {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (min-width: 1170px) {
  .saved-searches .saved-grid {
    grid-template-columns: repeat(4, 1fr);
  }
}
@media (min-width: 1300px) {
  .saved-searches .saved-grid {
    grid-template-columns: repeat(5, 1fr);
  }
}
.action-button-container {
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: center !important;
          align-items: center !important;
}
.card-action-icon.ant-tooltip-disabled-compatible-wrapper {
  width: 100% !important;
  padding: 0 !important;
}
.card-action-icon.ant-tooltip-disabled-compatible-wrapper button:disabled {
  background-color: #DEDEDE !important;
}
.card-action-icon {
  height: 20px !important;
  width: 18px !important;
  display: inline-table !important;
  -webkit-align-items: center !important;
          align-items: center !important;
  padding: 0 2px !important;
  font-size: 14px !important;
  border: 0 !important;
  line-height: 1.5 !important;
  color: #004cfe;
  cursor: pointer;
}
.card-action-icon svg {
  font-size: 14px !important;
}
.card-action-icon a {
  color: #004cfe;
}
.card-action-icon:disabled {
  background-color: #DEDEDE !important;
}
.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
  border-right: 0;
}
.ant-menu-vertical.ant-menu {
  background: transparent;
}
@media (max-width: 768px) {
  .my-collection td:empty {
    padding: 0 !important;
  }
  .sm-ml-2 {
    margin-left: 2px;
  }
}
@media (min-width: 768px) {
  .content-height-restricted {
    max-height: 100vh;
    overflow-y: scroll;
  }
}
.instructions {
  text-align: left;
  font-size: 16px;
  margin: 15px 0px 30px;
}
.rowCaution {
  background-color: lightyellow;
}
.cell-pointer {
  cursor: pointer;
}
.ant-tabs-tab {
  font-size: 16px;
  color: black;
}
.ant-tabs-bar {
  border-bottom: 1px solid #d3ddec;
  border-radius: 4px;
  margin-bottom: 0;
}
.ant-tabs-nav {
  margin-bottom: 0 !important;
}
.ant-tabs-nav .ant-tabs-tab {
  border-top: 1px solid #d3ddec;
  border-right: 1px solid rgba(156, 172, 199, 0.25);
  background: #e9f0fb;
  font-size: 14px;
  text-transform: uppercase;
  font-weight: bold;
  border-top-right-radius: 4px;
  padding: 12px 22px;
  letter-spacing: -0.02em;
  margin-right: 0;
  color: #60749b;
}
.ant-tabs-nav .ant-tabs-tab:first-of-type {
  border-left: 1px solid rgba(156, 172, 199, 0.25);
}
.ant-tabs-nav .ant-tabs-tab-active {
  background: white;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  font-weight: bold;
  color: #004cfe !important;
}
.ant-tabs-nav .ant-tabs-tab-active > div {
  color: #004cfe !important;
}
.ant-tabs-tabpane {
  background: white;
  padding: 16px;
  border: 1px solid #fff;
  box-shadow: 1px 3px 60px rgba(156, 172, 199, 0.4);
  border-radius: 4px;
}
.filter-background .ant-tabs-tabpane {
  padding: 16px 0 0 0;
}
.ant-tabs-tabpane.ant-tabs-tabpane-active > p {
  -webkit-backface-visibility: hidden;
  color: black;
}
.graph-controls .ant-btn,
.ant-select .ant-select-selection,
.ant-select-selector,
input.ant-input,
.ant-input-number,
.sci-number-range-input .ant-input-number.ant-input-number,
.sci-number-range-input .ant-input-number + .ant-btn,
.ant-picker,
.sci-advanced-date-picker .ant-picker .ant-picker-input:not(.ant-input-disabled) {
  border: 1px solid #60749b !important;
  border-radius: 4px !important;
}
.card-filter-wizard .ant-picker {
  border: 0 !important;
}
.ant-input::-webkit-input-placeholder {
  color: #b3b3b3;
}
.ant-btn {
  border-radius: 4px;
}
.ant-table-cell .ant-btn-group {
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.ant-btn-group > span:last-child:not(:first-child) > .ant-btn {
  border-radius: 0 !important;
}
.ant-input-group.ant-input-group-compact > *:first-child,
.ant-input-group.ant-input-group-compact > .ant-select:first-child > .ant-select-selector,
.ant-input-group.ant-input-group-compact > .ant-select-auto-complete:first-child .ant-input,
.ant-input-group.ant-input-group-compact > .ant-cascader-picker:first-child .ant-input,
.ant-btn-group > .ant-btn:first-child:not(:last-child),
.ant-btn-group > span:first-child:not(:last-child) > .ant-btn {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.ant-btn-group > .ant-btn:last-child:not(:first-child),
.ant-btn-group > span > .ant-btn:last-child:not(:first-child) {
  border-top-right-radius: 4px !important;
  border-bottom-right-radius: 4px !important;
}
.ant-input-group > .ant-input:first-child,
.ant-input-group-addon:first-child {
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.ant-input-group-addon {
  border-radius: 4px;
}
.ant-input-search > .ant-input-group > .ant-input-group-addon:last-child .ant-input-search-button {
  border-radius: 0 4px 4px 0;
}
.ant-btn-primary {
  background: -webkit-linear-gradient(left, #004cfe 0%, #0035b0 100%) !important;
  background: linear-gradient(to right, #004cfe 0%, #0035b0 100%) !important;
  border-radius: 4px;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus,
.ant-btn-primary:active {
  background: -webkit-linear-gradient(left, #0035b0 0%, #0035b0 100%) !important;
  background: linear-gradient(to right, #0035b0 0%, #0035b0 100%) !important;
}
.ant-btn-primary:disabled {
  background: rgba(0, 53, 176, 0.25) !important;
  color: white !important;
}
.ant-btn-background-ghost {
  color: #ffffff;
  background: rgba(0, 0, 0, 0) !important;
  border-color: #ffffff;
}
.ant-btn-background-ghost.ant-btn-primary {
  color: #004cfe;
  background: rgba(0, 0, 0, 0);
  border-color: #004cfe;
  text-shadow: none;
}
.ant-btn-background-ghost.ant-btn-primary:focus,
.ant-btn-background-ghost.ant-btn-primary:hover {
  color: white;
}
.search-tags .ant-tag-blue {
  border-radius: 3px;
  background: #f3f7fd;
  border: 1px solid #60749b;
  color: #60749b;
}
.ant-pagination .ant-pagination-item-active {
  border-color: #60749b;
}
.ant-pagination .ant-pagination-item-active a {
  color: #60749b;
}
.saved-well {
  padding: 15px;
  margin-bottom: 30px;
  background: white;
  border: 1px solid #fff;
  box-shadow: 1px 3px 60px rgba(156, 172, 199, 0.4);
  border-radius: 4px;
}
.ant-table-row-collapsed::after {
  content: "+";
  color: #60749b;
  font-weight: bold;
  font-size: 15px;
}
.ant-table .ant-table-row-expand-icon {
  border-radius: 3px;
  background: #e9f0fb;
  height: 16px;
  border-color: #b5c8ed;
}
.ant-table .ant-table-row-expand-icon :after {
  display: none;
}
.ant-table-row-expand-icon:after {
  content: unset !important;
}
div.lockable-tabs .ant-tabs-tab-disabled {
  color: rgba(0, 0, 0, 0.65) !important;
  cursor: default !important;
}
div.lockable-tabs .ant-tabs-tab-disabled .anticon-lock {
  color: #9b870c !important;
}
.editable-cell {
  position: relative;
}
.editable-cell-value-wrap {
  padding: 5px 12px;
  cursor: pointer;
}
.editable-row:hover .editable-cell-value-wrap {
  border: 1px solid #d9d9d9;
  border-radius: 4px;
  padding: 4px 11px;
}
.ant-table-tbody > tr.ant-table-row:hover > td {
  background: #e6f1ff !important;
}
.tab-nested-collapse.ant-collapse > .ant-collapse-item > .ant-collapse-header {
  background: #ececec;
  font-weight: bold;
}
.add-popular-card-group {
  -webkit-align-items: center;
          align-items: center;
}
.add-popular-card-group .ant-input {
  border-right: 0px;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}
.mobile-search-btn {
  display: none;
  z-index: 1000;
  width: 100%;
  background: -webkit-linear-gradient(#004cfe 0%, #0035b0 100%);
  background: linear-gradient(#004cfe 0%, #0035b0 100%);
  color: white;
  padding: 12px 22px;
  border: 0;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: -0.02em;
}
.sidebar-filter-collapse {
  margin-bottom: 100px;
}
.search-filter-popup {
  width: 100%;
  height: 42px;
  z-index: 100;
  overflow-y: auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  position: fixed;
  bottom: 0;
}
.search-filter-popup.fixed-menu {
  position: fixed;
  min-height: 100%;
  height: auto;
  top: 67px;
  bottom: 0;
}
.search-filter-popup .mobile-search-btn {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
}
.search-filter-popup .filter-wizard-wrapper {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
  height: 100%;
}
.search-filter-popup .card-filter-wizard {
  height: 100%;
}
@media (max-width: 768px) {
  .\/playercharts #help-btn,
  .\/setyearcharts #help-btn,
  .\/intelligence\-reports\/grade\-ratios #help-btn,
  .\/intelligence\-reports\/variation\-ratios #help-btn,
  .\/intelligence\-reports\/player\-ratios #help-btn {
    bottom: 60px !important;
  }
  .sidebar-filter-collapse {
    margin-bottom: 0;
    padding-bottom: 67px !important;
  }
  .ant-tabs-nav .ant-tabs-tab-active {
    border: 0 !important;
    background: transparent !important;
    position: relative;
  }
  .ant-tabs-nav .ant-tabs-tab-active:after {
    content: "";
    position: absolute;
    bottom: 0px;
    left: 0;
    z-index: 1;
    -moz-box-sizing: border-box;
         box-sizing: border-box;
    height: 45px;
    width: 45px !important;
    background-color: #ffffff;
    -webkit-transform-origin: 0 0;
            transform-origin: 0 0;
    -webkit-transform: rotate(45deg) !important;
            transform: rotate(45deg) !important;
    left: 50%;
    top: 35px;
    right: -50%;
  }
  .ant-tabs-nav .ant-tabs-tab {
    border: 0 !important;
    background: transparent;
    font-size: 12px;
    padding: 16px 22px;
  }
  .ant-tabs-ink-bar {
    display: none !important;
  }
  .ant-tabs-bar {
    border: 0 !important;
  }
  .ant-tabs-content {
    box-shadow: 0px -3px 20px rgba(2, 46, 149, 0.02);
  }
  .mobile-menu {
    padding: 0 !important;
  }
  .ant-layout-sider-zero-width-trigger {
    display: none !important;
  }
  .mobile-search-btn {
    display: block;
  }
}
#chart .ant-tabs-tabpane {
  border: 0 !important;
}
.myc-add-card-modal {
  min-width: 675px !important;
}
@media (max-width: 768px) {
  .myc-add-card-modal {
    min-width: unset !important;
    width: 95% !important;
  }
}
.pcc-sidebar-mobile-visibility {
  display: block;
}
@media (min-width: 768px) {
  .pcc-sidebar-mobile-visibility {
    display: none;
  }
}
.pcc-sidebar-desktop-visibility {
  display: none;
}
@media (min-width: 768px) {
  .pcc-sidebar-desktop-visibility {
    display: block;
  }
}
/*
* Mobile menu adjustments
*/
.mobile-menu-btn {
  border: 0;
  background: none;
  color: white;
  width: 60px;
}
.mobile-menu-btn:focus,
.mobile-menu-btn:active,
.mobile-menu-btn:hover {
  color: #d6d6d6;
  border: 0;
  background: none;
  outline: none;
  cursor: pointer;
}
@media (min-width: 1170px) {
  .mobile-menu-btn,
  .mobile-menu {
    display: none !important;
  }
}
td.ratio-column div {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
td.ratio-column i,
td.ratio-column svg {
  margin-right: 8px;
}
@media (min-width: 769px) {
  td.ratio-column div {
    -webkit-justify-content: center;
            justify-content: center;
  }
  td.ratio-column i,
  td.ratio-column svg {
    margin-left: -14px;
    margin-right: 2px;
  }
}
.intl-reports-statistic .ant-statistic-content-value-decimal {
  font-size: inherit;
}
.chartpane-outer-div {
  padding: 10px;
}
@media (max-width: 768px) {
  .chartpane-outer-div {
    padding: 0;
  }
}
.card-stats-table-singular-data {
  font-weight: bold;
  font-size: 16px;
  color: black;
}
.ant-input-search-button {
  border: 1px solid #60749b;
  border-left: 0;
  height: 35px;
}
.ant-input-group.ant-input-group-compact input {
  height: 32px !important;
}
.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
button.ant-btn-icon-only {
  height: 35px;
}
.ant-btn-circle {
  border-radius: 50% !important;
  height: auto !important;
}
.ant-form-extra {
  margin-top: 0;
}
@media (max-width: 1170px) {
  .ant-layout.ant-layout-has-sider > .ant-layout,
  .ant-layout.ant-layout-has-sider > .ant-layout-content {
    width: auto;
  }
}
.ant-tabs-tab + .ant-tabs-tab {
  margin: 0 !important;
}
.ant-table-wrapper.empty-table {
  max-width: 87vw !important;
}
.ant-input-affix-wrapper .ant-input {
  border: 0 !important;
}
.ant-btn-round {
  border-radius: 32px !important;
}
.ant-table-wrapper {
  width: 87vw !important;
}
#sci-overlay-search,
.ant-input-wrapper.ant-input-group > input {
  border-right: 0 !important;
  height: 35px;
}
#sci-overlay-search:focus,
.ant-input-wrapper.ant-input-group > input:focus {
  box-shadow: none;
}
.ant-input-wrapper.ant-input-group:focus-within {
  box-shadow: 0px 0 0 2px rgba(24, 144, 255, 0.2);
}
.ant-tabs-top > .ant-tabs-nav:before {
  border-bottom: 0 !important;
}
.sr-only {
  border: 0 !important;
  clip: rect(1px, 1px, 1px, 1px) !important;
  /* 1 */
  -webkit-clip-path: inset(50%) !important;
  clip-path: inset(50%) !important;
  /* 2 */
  height: 1px !important;
  margin: -1px !important;
  overflow: hidden !important;
  padding: 0 !important;
  position: absolute !important;
  width: 1px !important;
  white-space: nowrap !important;
  /* 3 */
}
.ant-modal-content .ant-picker-input > input {
  text-align: left !important;
}
.ant-modal-content .ant-picker-range .ant-picker-input > input {
  text-align: center !important;
}
.ranking-table-item {
  background-color: white;
}
.ranking-table-item:nth-child(2n) {
  background-color: #f0f0f0;
}
.ranking-table-item-disabled {
  background-color: #b4b4b4;
}
.ranking-table-item-disabled td img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.app-ranking-run-job {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  margin-bottom: 20px;
  padding-right: 10px;
}
.app-ranking-run-job span {
  margin-left: 10px;
}
.app-ranking-filters {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
}
.app-ranking-filters > div {
  padding: 0 10px;
}
.ant-modal-content .collection-modal-range-picker .ant-picker-input input {
  text-align: center !important;
}
div.dd-box {
  background-color: white;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.09), 0 6px 6px rgba(0, 0, 0, 0.13);
  padding: 10px 0;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}
.mobile-menu .dropdown-main-container {
  display: -webkit-flex;
  display: flex;
  margin-right: 10px;
  font-size: 16px;
}
.mobile-menu .dropdown-main-container .selected-option {
  margin-right: 10px;
}
.mobile-menu .dropdown-container {
  height: 100vh;
  width: 100vw;
  z-index: 1;
  background-color: #004CFE;
  position: absolute;
  left: 0;
  top: 60px;
}
.mobile-menu .dropdown-container .option {
  height: 60px;
  font-size: 20px;
  padding: 0 20px;
}
@media screen and (max-width: 1170px) {
  .mobile-menu .search-box-container {
    padding: 10px;
    height: 40px;
  }
  .mobile-menu .dropdown-main-container {
    min-width: 130px;
    margin-left: 20px;
  }
  .mobile-menu .search-box-container .ant-input-affix-wrapper {
    line-height: unset;
  }
  .mobile-menu .mobile-header-quick-search-trigger {
    width: 100%;
    height: unset;
  }
}
.dragAndDropContainer {
  margin: 10px 0;
}
.dragAndDropContainer .spinContainer {
  width: 100%;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  height: 100px;
}
.dragAndDropContainer .dragRow {
  padding: 0 20px;
  background: #fff;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  cursor: move;
  border-bottom: 1px solid grey;
  height: 60px;
}
.dragAndDropContainer .dragRow .title {
  width: 50%;
}
.dragAndDropContainer .dragRow .title .icon {
  margin-right: 10px;
}
.dragAndDropContainer .dragRow .status {
  width: 15%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: center;
}
.dragAndDropContainer .dragRow .value {
  width: 15%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  text-align: end;
}
.dragAndDropContainer .dragRow .actionContainer {
  width: 20%;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.dragAndDropContainer .dragRow:last-child {
  border-bottom: none;
}
.manage-indices .headerContainer {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 40px;
}
.manage-indices .headerContainer .leftSide {
  width: 50%;
}
.manage-indices .headerContainer .leftSide .descriptionContainer {
  margin-right: 30px;
}
.manage-indices .headerContainer .rightSide {
  width: 50%;
  padding-left: 2%;
  height: 100px;
}
.manage-indices .headerContainer .rightSide .titleContainer {
  display: -webkit-flex;
  display: flex;
}
.manage-indices .headerContainer .rightSide .checkboxContainer {
  margin-top: 10px;
}
.manage-indices .headerContainer .rightSide .statsSection {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-align-items: center;
          align-items: center;
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 10px;
}
.manage-indices .headerContainer .rightSide .statsSection .valueStatsContainer {
  display: -webkit-flex;
  display: flex;
}
.manage-indices .headerContainer .rightSide .statsSection .valueStatsContainer .valueStats {
  border: 3px solid black;
  margin-left: 10px;
  background-color: #fff;
  text-align: center;
  padding: 5px 10px;
}
.manage-indices .headerContainer .rightSide .statsSection .valueStatsContainer .valueStats hr {
  margin: 2px;
}
.manage-indices .headerContainer .rightSide .statsSection .valueStatsContainer .valueStats .value {
  font-size: 20px;
}
.manage-indices .mainContainer {
  display: -webkit-flex;
  display: flex;
}
.manage-indices .mainContainer .leftSection {
  width: 48%;
}
.manage-indices .mainContainer .middleSection {
  position: relative;
  display: -webkit-flex;
  display: flex;
  padding-top: -webkit-calc(20% + 10px);
  padding-top: calc(20% + 10px);
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 4%;
}
.manage-indices .mainContainer .middleSection button {
  margin-bottom: 10px;
}
.manage-indices .mainContainer .rightSection {
  width: 48%;
}
.manage-indices .searchBar {
  width: 100%;
  margin-bottom: 10px;
}
.manage-indices .cardSearchTable {
  max-height: 650px;
}
.manage-indices .cardSearchTable th {
  min-width: 80px;
  text-align: center;
  padding: 16px 0;
  color: #FFFFFF;
  background-color: #004cfd;
}
.manage-indices .cardSearchTable th.ant-table-column-sort {
  background-color: #004cfd;
}
.manage-indices .cardSearchTable th.ant-table-column-has-sorters:hover {
  background-color: #004cfd;
}
.manage-indices .cardSearchTable td {
  background-color: #FFFFFF;
  padding: 16px 0;
}
.manage-indices .cardSearchTable td:nth-child(2n+4) {
  -webkit-filter: brightness(0.85);
          filter: brightness(0.85);
}
.manage-indices .cardSearchTable td:nth-child(n+4) {
  text-align: center;
}
.manage-indices .cardSearchTable .endAvg {
  font-weight: bold;
  font-size: 16px;
}
.manage-indices .cardSearchTable .saleDate {
  font-size: 16px;
}
.manage-indices .cardSearchTable .last90_sales_volume {
  font-size: 16px;
}
.manage-indices .cardSearchTable .last365_sales_volume {
  font-size: 16px;
}
.manage-indices .cardSearchTable .image {
  height: 110px;
}
.manage-indices .cardSearchTable .titleSection {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-evenly;
          justify-content: space-evenly;
  margin-left: 8px;
}
.manage-indices .cardSearchTable .titleSection .playerName {
  font-size: 16px;
}
.manage-indices .cardSearchTable .titleSection .description {
  font-size: 16px;
}
.manage-indices .cardSearchTable .titleSection .tags {
  display: -webkit-flex;
  display: flex;
}
.manage-indices .cardSearchTable .titleSection .tags .tag {
  background-color: #004cfd;
  padding: 5px 0;
  color: #fff;
  border-radius: 5px;
  margin-right: 5px;
  width: 50px;
  text-align: center;
  font-size: 10px;
}
.manage-indices .cardSearchTable .titleSection .tags .tag-rc {
  background-color: #eaa221;
}
.manage-indices-table .actionContainer {
  width: 100px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.market-pulse-page .titleContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}
.market-pulse-page .chart-fullscreen {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}
.market-pulse-page .selectionContainer {
  margin-bottom: 20px;
  display: -webkit-flex;
  display: flex;
}
.market-pulse-page .selectionContainer .shareSelectionButton {
  margin-left: 6px;
  height: 32px;
}
.market-pulse-page .selectionContainer .shareSelectionButton .ant-btn {
  height: 32px;
}
.market-pulse-page .selectionContainer .colorLabel {
  width: 30px;
  height: 28px;
  margin-right: 6px;
  margin-top: 1px;
  border-radius: 15px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}
.market-pulse-page .selectionContainer .colorLabel .infoIcon {
  color: #fff;
  font-size: 20px;
}
.market-pulse-page .chartContainer .tabs {
  margin: 20px 0;
  width: 100%;
}
.market-pulse-page .chartContainer .tabs .upperControls {
  display: -webkit-flex;
  display: flex;
  width: 100%;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.market-pulse-page .chartContainer .tab {
  position: relative;
  height: 400px;
  width: 100%;
}
.market-pulse-page .chartContainer .tab .tooltipContainer {
  background-color: #fff;
  padding: 10px;
  border: 1px solid grey;
}
.market-pulse-page .chartContainer .rangePickerContainer {
  display: -webkit-flex;
  display: flex;
  margin-bottom: 20px;
}
.market-pulse-page .chartContainer .rangePickerContainer .rangePicker {
  width: 400px;
}
.market-pulse-page .indexChooserContainer {
  margin-top: 10px;
}
.market-pulse-page .indexChooserContainer .halfContainer {
  padding: 0 10px;
}
.market-pulse-page .indexChooserContainer .select {
  width: 100%;
}
.market-pulse-page .cardTable {
  margin-top: 20px;
}
@media screen and (max-width: 900px) {
  .market-pulse-page .cardTable .ant-table-cell {
    padding-left: 4px;
    padding-right: 4px;
  }
}
.market-pulse-page .cardTable .tableImageContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  width: 90px;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
}
.market-pulse-page .cardTable .tableImageContainer img {
  height: 120px;
  cursor: pointer;
  margin-bottom: 6px;
}
.market-pulse-page .cardTable .titleContainer {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}
.market-pulse-page .cardTable .titleContainer div {
  margin: 3px 0;
}
.market-pulse-page .cardTable .titleContainer .player {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
  line-height: 18px;
}
.market-pulse-page .cardTable .titleContainer .popCountLink {
  padding: 0;
}
.market-pulse-page .chartSettings {
  margin-bottom: 10px;
}
.market-pulse-page .pulseStatisticContainer .statistic {
  text-align: center;
}
.market-pulse-page .pulseStatisticContainer .statistic .ant-statistic-content {
  font-weight: bold;
  font-size: 14px;
}
.undoBox {
  max-height: 600px;
  overflow: scroll;
}
.undoBox img {
  width: 50px;
  margin-right: 20px;
}
.undoBox div {
  margin: 10px 0;
}
.platform-logo {
  width: 80px;
  height: 40px;
  background-size: cover;
  background-repeat: no-repeat;
  margin: 0 auto;
}
.platform-logo.pwcc {
  background-image: url("/icons/platforms/pwcc.jpg");
}
.platform-logo.goldin {
  background-image: url("/icons/platforms/goldin.jpg");
}
.platform-logo.myslabs {
  background-image: url("/icons/platforms/myslabs.jpg");
}
.platform-logo.ebay {
  background-image: url("/icons/platforms/ebay.jpg");
}
.platform-logo.scp {
  background-image: url("/icons/platforms/scp.jpg");
}
.platform-logo.rea {
  background-image: url("/icons/platforms/rea.jpg");
}
.platform-logo.ml {
  background-image: url("/icons/platforms/ml.jpg");
}
.tooltipContainer {
  background: white;
  padding: 10px;
  border: 1px solid #cccccc;
}
.tooltipContainer .tooltipList {
  padding: 0;
  list-style-type: none;
  margin: 0;
}
.tooltipContainer .tooltipList .tooltipListItem {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}
.tooltipContainer .tooltipList .tooltipListItem:not(:last-child) {
  border-bottom: 1px solid #cccccc;
}
.tooltipContainer .tooltipList .tooltipListItem .tooltipHighlightBar {
  width: 10px;
  height: 50px;
  margin-right: 14px;
}
.tooltipContainer .tooltipList .tooltipListItem .tooltipHighlightedValue {
  font-size: 14px;
}
.tooltipContainer .tooltipList .tooltipListItem .tooltipHighlightedValue:first-child {
  width: 35%;
}
.tooltipContainer .tooltipList .tooltipListItem .tooltipDataContainer {
  width: 100%;
}
.tooltipContainer .tooltipList .tooltipListItem .tooltipValuesContainer {
  display: -webkit-flex;
  display: flex;
}
.tooltipContainer .tooltipLabel {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  margin-bottom: 10px;
  font-size: 16px;
}
.excluded-table .excluded-admin-button {
  margin-top: 5px;
}
.excluded-table .admin-area {
  height: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
}
.excluded-table .excluded-table-image {
  width: 200px;
}
.excluded-table .ant-checkbox {
  height: 24px;
  width: 24px;
  border: 1px solid lightgrey;
  border-radius: 5px;
  background-color: #fff;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.excluded-table .ant-checkbox.ant-checkbox-checked {
  background-color: #004cfe;
}
.excluded-table .ant-checkbox.ant-checkbox-indeterminate {
  background-color: #004cfe;
}
.excluded-table .ant-checkbox.ant-checkbox-indeterminate .ant-checkbox-inner {
  display: none;
}
.excluded-table .ant-checkbox .ant-checkbox-inner {
  border: none;
  -webkit-transition: 0.3s ease-out;
  transition: 0.3s ease-out;
}
.excluded-table .ant-checkbox :after {
  height: 10px;
  left: 7px;
  top: 10px;
}
